<script setup lang="ts">
import { Tab_Filter_Type } from '~/enums/tab_filter-type'
import { FreeVipApi } from '~/net/apis/free_vip_api'

const videoTypeList = [
  {
    tabId: Tab_Filter_Type.newest,
    title: '最新作品'
  },
  {
    tabId: Tab_Filter_Type.watch,
    title: '最多观看'
  },
  {
    tabId: Tab_Filter_Type.collect,
    title: '最多收藏'
  }
]
const onClickLeft = () => history.back()
const active = ref(Tab_Filter_Type.newest)
</script>

<template>
  <div>
    <van-nav-bar title="VIP区" left-arrow @click-left="onClickLeft" />
    <van-tabs v-model:active="active">
      <van-tab v-for="item in videoTypeList" :key="item.tabId" :name="item.tabId" :title="item.title">
        <PullRefreshListBottom
          wrap-class="w-full grid gap2 px-15px"
          :request-api="FreeVipApi.query"
          :offset-list="20"
          :request-params="{
            orderKey: active,
            viewType: 1
          }"
        >
          <template #="{ data }">
            <div class="w-full grid grid-cols-1">
              <VertucakCard :item="(data[0] as BannerFilmModel).javVideoBaseDTO" />
            </div>
            <div class="w-full overflow-auto sroll-view">
              <div class="w-452px grid gap2 grid-cols-3">
                <div v-for="(item, index) in data.slice(1, 4)" :key="index">
                  <VertucakCard :item="(item as BannerFilmModel).javVideoBaseDTO" />
                </div>
              </div>
            </div>
            <div class="w-full grid gap2 grid-cols-2">
              <div v-for="(item, index) in data.slice(4)" :key="index">
                <VertucakCard :item="(item as BannerFilmModel).javVideoBaseDTO" />
              </div>
            </div>
          </template>
        </PullRefreshListBottom>
      </van-tab>
    </van-tabs>
  </div>
</template>

<style scoped>
.sroll-view{
  overflow-x: scroll;
  &::-webkit-scrollbar{
    display: none;
    width: 0.01rem;
  }
}
</style>
